<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';

export default {
  components: {Navbar,Footer},
};
</script>

<template>

  <Navbar/>
    <h1>Logged out Successfully</h1>
  <Footer/>
</template>

<style scoped>
.navbar {
  display: flex;
  padding: 0 1rem;
  background-color: rgb(234, 234, 255);
  align-items: center;
  height: 10vh;
  margin-bottom: 5px;
}

.links {
  padding: 10px 0px;
  display: flex;
}

.links .btn {
  border-radius: 5px;
}

.btn a {
  border: 1px solid #2aa2db;
  padding: 10px 25px;
  color: #2aa2db;
  border-radius: 10px;
}

/* ============================== */
.form-div {
  margin-left: 50%;
  transform: translateX(-50%);
  /* text-align: center; */
}

.form-div h1 {
  text-align: center;

}

.links ul {
  display: flex;
  gap: 20px;
}
.column {
  display: flex;
  flex-direction: column;
}

.form-div {
  max-width: 30rem;
  padding: 1rem;
}

.w-100{
  height: 40px;
}
</style>
